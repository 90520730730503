import { Container, Link, Typography } from "@mui/material";
import { NavigationAndFooterLayout } from "../layouts";
import { NextPageWithLayout } from "@cm-websites/common";
import { NextSeo } from "next-seo";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import i18nConfig from "../../next-i18next.config";
import React from "react";
import type { GetStaticProps } from "next";

export const NotFound404Page: NextPageWithLayout = ({}) => (
  <React.Fragment>
    <NextSeo nofollow noindex title="404 | Culinario Mortale®" />
    <Container
      maxWidth="md"
      sx={{ pt: { xs: 12, sm: 14, lg: 16 }, pb: { xs: 8, sm: 10, lg: 14, xl: 16 } }}
    >
      <Typography variant="h1" sx={{ pb: 6 }}>
        Nous vous prions de nous excuser
      </Typography>
      <Typography variant="body1" paragraph>
        Quelque chose s'est mal passé ici. Nous n'avons malheureusement pas pu trouver la page que tu
        cherches. Veuillez utiliser la navigation pour accéder à une autre page.
      </Typography>
      <Typography variant="body1" paragraph>
        Si tu as des questions, tu peux aussi nous contacter à tout moment à l'adresse{" "}
        <Link href="mailto:support@culinario-mortale.fr">support@culinario-mortale.fr</Link>.
      </Typography>
    </Container>
  </React.Fragment>
);

NotFound404Page.getLayout = (page) => <NavigationAndFooterLayout>{page}</NavigationAndFooterLayout>;

export default NotFound404Page;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(locale
        ? await serverSideTranslations(locale, ["c-common", "c-user-consent-management"], i18nConfig)
        : {}),
    },
  };
};
